<template>
  <div
    class="fixed w-72 right-0 h-full bg-white rounded-l-2xl p-10 pt-20 overflow-y-auto overflow-x-hidden"
  >
    <img :src="branch.branch_image" class="rounded-full" />
    <h1 class="mt-5 font-bold text-3xl text-center">
      {{ branch.branch_name }}
      <small>{{ branch.branch_id }}</small>
    </h1>
    <h2 class="text-lg text-center text-gray-600 pb-5 mb-5 border-b">
      Branch office
    </h2>
    <h3 class="font-bold mb-3">Contact Info</h3>
    <div class="flex gap-3 border-b py-2 mb-3">
      <message-icon />
      <div class="">
        {{ branch.branch_email }}
      </div>
    </div>
    <div class="flex gap-3 border-b py-2 mb-3">
      <phone-icon />
      <div class="">
        {{ branch.branch_phone }}
      </div>
    </div>
    <div class="flex gap-3 border-b py-2 mb-3">
      <location-icon />
      <div class="">
        {{ branch.branch_address }}
      </div>
    </div>
    <div class="flex gap-3 border-b py-2 mb-3">
      <user-icon class="opacity-50" />
      <div class="">Null</div>
    </div>
    <div class="flex gap-3 border-b py-2 mb-3">
      <DNS-icon class="opacity-50 w-10" />
      <div class="">
        Working with the Government Hospitals (DT/SN) -
        {{ branch.branch_working_with_government_hospital !== 0 }}
      </div>
    </div>
    <div class="flex gap-3 border-b py-2 mb-3">
      <permission-icon class="opacity-50" />
      <div class="">Null</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "EditBranchModal",
  props: {
    branch: {
      required: true,
      type: Object,
    },
  },
  components: {
    MessageIcon: () => import("@/assets/icons/MessageIcon.vue"),
    PhoneIcon: () => import("@/assets/icons/PhoneIcon.vue"),
    LocationIcon: () => import("@/assets/icons/LocationIcon.vue"),
    UserIcon: () => import("@/assets/icons/UserIcon.vue"),
    DNSIcon: () => import("@/assets/icons/DNSIcon.vue"),
    PermissionIcon: () => import("@/assets/icons/PermissionIcon.vue"),
  },
};
</script>

<style></style>
